




















































































































import {
  computed,
  defineComponent, ref,
} from '@nuxtjs/composition-api';

import SimpleButton from '~/components/common/SimpleButton.vue';
import { SimpleImage } from '~/components/common/SimpleImage';
import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import SvgImage from '~/components/General/SvgImage.vue';
import ProductPrice from '~/components/Price.vue';
import { useExternalCheckout, useTax, useUiState } from '~/composables';

export default defineComponent({
  name: 'AfterAddToCartModal',
  components: {
    SimpleButton,
    ProductPrice,
    SimpleImage,
    SvgImage,
    SimpleModal,
  },
  setup() {
    const { modal } = useUiState();
    const { vatLabel, getCalculatedPrice } = useTax();
    const { initializeCheckout } = useExternalCheckout();

    const isCloseTriggered = ref(false);

    const product = computed(() => modal.value?.props?.product);
    const buyTogetherProduct = computed(() => (modal.value?.props?.isBuyTogether ? product.value?.buy_together : null));
    const quantity = computed(() => modal.value?.props?.qty);

    const handleClose = () => {
      isCloseTriggered.value = true;

      setTimeout(() => {
        isCloseTriggered.value = false;
      }, 100);
    };
    const goToCart = () => {
      // We should initialize checkout with the current url to keep the user on the same page
      initializeCheckout({ baseUrl: window.location.href });
    };

    return {
      product,
      buyTogetherProduct,
      quantity,
      vatLabel,
      isCloseTriggered,
      name: MODAL_NAMES.afterAddToCart,
      getCalculatedPrice,
      handleClose,
      goToCart,
    };
  },
});
